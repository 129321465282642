import banner from 'assets/images/welcome-bg.jpg'
import AuthSection from 'components/auth/AuthSection'
import UserAuthLayout from 'layouts/UserAuth'

const LoginPage = () => {
  return (
    <UserAuthLayout
      banner={{
        banner: banner,
        alt_text: 'A beautiful view in the French Riviera at Twilight',
      }}
    >
      <AuthSection buttonStyle="default" />
    </UserAuthLayout>
  )
}

export default LoginPage
