import LogoLink from 'components/logoLink'
import Image, { StaticImageData } from 'components/Image'
import { ReactChild } from 'data/types/types'
import IconButton from 'components/IconButton'
import { useRouter } from 'next/router'
import { ArrowLeftIcon } from 'components/icons'

interface UserAuthLayoutProps {
  children?: ReactChild
  banner: {
    banner: string | StaticImageData
    alt_text: string
  }
  back?: boolean
}

const UserAuthLayout = ({
  children,
  banner,
  back = true,
}: UserAuthLayoutProps) => {
  const router = useRouter()

  return (
    <div className="flex flex-col items-center w-full h-screen md:flex-row pt-safe">
      <div className="hidden md:block relative h-[235px] md:h-full w-full">
        <Image
          src={banner.banner}
          alt={banner.alt_text}
          className="object-cover"
          layout="fill"
        />
      </div>

      <div className="flex flex-col h-full w-full mx-auto md:max-w-[448px] relative">
        {back
          ? <div className="absolute z-20 left-15 top-15">
            <IconButton
              variant="link"
              onClick={() => router.back()}
            >
              <ArrowLeftIcon className="stroke-2 text-24 text-blue-1000" />
            </IconButton>
          </div>
          : null
        }

        <LogoLink
          className="!w-full !max-w-[127px] mt-20 mx-auto"
          variant="no-tag-line"
        />

        {children}
      </div>
    </div>
  )
}

export default UserAuthLayout
